@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "poppins";
  src: local("poppins"),
    url(./fonts/Poppins/Poppins-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "roboto";
  src: local("RobotoCondensed"),
    url(./fonts/Roboto/RobotoCondensed-Regular.ttf) format("truetype");
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: white;
  transition: background-color 5000s ease-in-out 0s;
}

@layer base {
  html {
    @apply bg-mat-black;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    font-size: 12px;
  }

  body {
    margin: 0;
  }
}

body::-webkit-scrollbar {
  display: none;
}

@layer components {
  .main {
    @apply bg-red-100 h-screen w-full;
  }

  .header {
    @apply bg-black fixed top-0 w-full h-[63px] border-orange-1 flex pr-10;
  }

  .main-logo {
    @apply flex items-center justify-evenly;
  }
}

@keyframes disappear {
  0% {
    display: block;
    opacity: 1;
  }

  25% {
    opacity: 0.75;
  }

  50% {
    opacity: 0.5;
  }

  75% {
    opacity: 0.25;
  }

  100% {
    opacity: 0;
    display: none;
  }
}

.loaderWrapper {
  float: left;
  width: 100px;
  background-color: rgba(0, 0, 0, 0.02);
  height: 100px;
  vertical-align: middle;
}

.loader {
  margin: 40px auto;
  position: relative;
  width: 8px;
  height: 8px;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: -14px 0 0 rgba(255, 255, 255, 1);
  border-radius: 50%;
  -webkit-animation: circle_classic 1s ease-in-out infinite alternate;
  -moz-animation: circle_classic 1s ease-in-out infinite alternate;
  animation: circle_classic 1s ease-in-out infinite alternate;
}

@-webkit-keyframes circle_classic {
  0% {
    opacity: 0.1;
    -webkit-transform: rotate(0deg) scale(0.5);
  }

  100% {
    opacity: 1;
    -webkit-transform: rotate(360deg) scale(1.2);
  }
}

@-moz-keyframes circle_classic {
  0% {
    opacity: 0.1;
    -moz-transform: rotate(0deg) scale(0.5);
  }

  100% {
    opacity: 1;
    -moz-transform: rotate(360deg) scale(1.2);
  }
}

@keyframes circle_classic {
  0% {
    opacity: 0.1;
    transform: rotate(0deg) scale(0.5);
  }

  100% {
    opacity: 1;
    transform: rotate(360deg) scale(1.2);
  }
}

.disappear {
  animation-name: disappear;
  animation-duration: 800ms;
  animation-fill-mode: forwards;
}

.preview-hover:hover .previewImg {
  display: none;
}

.bg-gradient-black {
  background: linear-gradient(270deg, #000000 0%, rgba(0, 0, 0, 0) 70.48%);
}

.bg-top-controls {
  background: linear-gradient(180deg,
      #1c1c1c 0%,
      rgba(28, 28, 28, 0.67) 81%,
      rgba(28, 28, 28, 0) 100%);
}

.bg-bottom-controls {
  background: linear-gradient(180deg,
      rgba(28, 28, 28, 0) 0%,
      rgba(28, 28, 28, 0.67) 25%,
      #1c1c1c 100%);
}

@screen md {
  .bg-top-controls {
    background: linear-gradient(180deg,
        #1c1c1c 0%,
        rgba(28, 28, 28, 0.67) 21%,
        rgba(28, 28, 28, 0) 60%);
  }

  .bg-bottom-controls {
    background: linear-gradient(180deg,
        rgba(28, 28, 28, 0) 14.5%,
        rgba(28, 28, 28, 0.67) 55%,
        #1c1c1c 100%);
  }
}

.avatar {
  padding-left: 2vw;
}

.btn-subscribe {
  background-color: #49fcd9;
  border-radius: 1.65rem;
  padding: 0.3em 1em 0.3em 1em;
  font-size: 1.4em;
  font-weight: 700;
  font-family: Roboto, sans-serif;
}

.search-input {
  background-color: #101010;
  font-size: 1.4em;
  color: #858585;
  min-width: 12vw;
}

.border-orange-1 {
  border-bottom: 1px solid #e35b2c;
}

.gray-7 {
  color: #777;
}

.gray-4 {
  color: #444;
}

.gray-53 {
  color: #535353;
}

.star-color {
  color: #ff9b38;
}

.star-bg {
  background: #ff9b38;
}

.instructor-name {
  border-bottom: 1px solid #e35b2c;
  border-top: 1px solid #e35b2c;
}

.bg-gray-12 {
  background: rgba(12, 12, 12, 0.8);
}

.bg-gray-84 {
  background: rgba(84, 84, 84, 0.5);
}

.gallery-img {
  max-width: none;
}

.gallery ::-webkit-scrollbar-track-piece {
  display: none;
}

.text-infor {
  color: rgba(255, 255, 255, 0.5);
}

.bg-catergory-box {
  background: rgba(43, 47, 51, 1);
}

.w-30 {
  width: 30%;
}

.w-95 {
  width: 95%;
}

.w-20 {
  width: 20%;
}

.w-25 {
  width: 25%;
}

.h-screen-plus {
  height: 250vh;
}

#menu .active:nth-child(1)~.slide {
  top: 0;
}

#menu .active:nth-child(2)~.slide {
  top: 60px;
}

#menu .active:nth-child(3)~.slide {
  top: 120px;
}

#menu .active:nth-child(4)~.slide {
  top: 180px;
}

#menu .active:nth-child(5)~.slide {
  top: 240px;
}

#menu .active:nth-child(6)~.slide {
  top: 300px;
}

.active {
  color: #e35b2c;
}

@media screen and (max-width: 799px) {
  .active {
    border-top: 1px solid #e35b2c;
  }

  .menu-item {
    background-color: #101010;
  }
}

.slide {
  background: #101010;
  border-right: 2px solid #e35b2c;
  border-top: none;
  border-bottom: none;
  border-left: none;
  width: 100%;
  height: 51px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  transition: ease;
  transition-duration: 0.3s;
}

.menu-divider {
  width: 95%;
  float: right;
}

.text-orange {
  color: #e35b2c;
}

.shadow-r-orange-3 {
  box-shadow: 3px 0 0 0 #e35b2c;
}

.hide {
  display: none;
}

.show {
  display: block;
}

.text-category {
  color: rgba(221, 221, 221, 1);
}

.bg-catergory {
  color: rgba(221, 221, 221, 1);
}

.filter-shadow {
  box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.2);
}

.selected-category {
  background: rgba(72, 255, 222, 1);
  color: #000;
}

.text-selected-category {
  border-color: rgba(72, 255, 222, 1);
}

.border-mat-black {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.overlay-text-border {
  border-top: 1px solid rgba(73, 252, 217, 1);
  border-bottom: 1px solid rgba(73, 252, 217, 1);
}

.custom-name {
  border-top: 1px solid rgb(255, 229, 220);
  border-bottom: 1px solid rgb(255, 229, 220);
}

.text-poppins {
  font-family: "Poppins", sans-serif;
}

.bg-take-training {
  background: rgba(227, 91, 44, 1);
}

.text-take-training {
  color: rgba(227, 91, 44, 1);
}

.top-62 {
  top: 62px;
}

.viewSlider::-webkit-scrollbar-track,
.viewSlider::-webkit-scrollbar-thumb {
  border-radius: 10px;
  scrollbar-width: thin;
}

.strokeText {
  /* Will override color (regardless of order) */
  font-family: "poppins", sans-serif;
  color: #000;
  font-weight: 700;
  text-shadow: -1px 0 #49fcd9, 0 1px #49fcd9, 1px 0 #49fcd9, 0 -1px #49fcd9;
}

.black {
  background-color: rgba(12, 12, 12, 0.5);
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  border-radius: 20px;
  height: 8px;
  background: #dddddd;
  outline: none;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background: #ff652f;
  cursor: pointer;
  border: 4px solid rgba(72, 255, 222, 0.7);
}

.bg-player {
  background: linear-gradient(180deg,
      #1c1c1c 0%,
      rgba(28, 28, 28, 0.67) 6.28%,
      rgba(28, 28, 28, 0) 17.78%,
      rgba(28, 28, 28, 0) 74.9%,
      rgba(28, 28, 28, 0.67) 89.12%,
      #1c1c1c 100%);
}

.slider::-moz-range-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background: #ff652f;
  cursor: pointer;
  border: 4px solid rgba(72, 255, 222, 0.7);
}

.speedSlider {
  -webkit-appearance: none;
  width: 100%;
  border-radius: 20px;
  height: 5px;
  background: #dddddd;
  outline: none;
}

.speedSlider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 14px;
  height: 14px;
  border-radius: 127px;
  background: #5e5e5e;
  cursor: pointer;
}

.mirror {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
}

.rc-slider-handle-2 {
  display: none !important;
}

.radius-22 {
  border-radius: 22px;
}

.radius-tr-22 {
  border-radius: 0 22px 0 0;
}

.radius-93 {
  border-radius: 93px;
}

.step-item::after {
  content: "";
  position: absolute;
  z-index: -1;
  height: 1px;
  width: 95%;
  left: 50%;
  background-color: #fff;
  top: 30px;
  transform: translateX(20px);
}


@screen lg {
  .step-item::after {
    border-left: 1px solid white;
    height: calc(100% - 10px);
    width: auto;
    left: 30px;
    top: 100%;
    transform: translateY(-10px);
  }
}

.step-item:last-child::after {
  display: none;
}

.elementToFadeIn {
  opacity: 1;
  animation: fadeIn 1s linear;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.elementToFadeOut {
  opacity: 1;
  animation: fadeOut 1s linear;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.secondary-c {
  color: rgb(73, 252, 217);
}

.secondary-b-c {
  border-color: rgb(73, 252, 217);
}

.dance-style-active::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 0.375rem;
  background: rgb(73, 252, 217);
  opacity: 0.7;
  z-index: 2;
}

.filterSlider>.rc-slider-handle-2 {
  display: block !important;
}


.video-thumbnail {
  position: relative;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 56%;
}

.text-l {
  font-size: 20px;
}

.black-900 {
  background-color: #1C1C1C;
}

@media (min-width: 1300px) {
  .lg\:w-30 {
    width: 30%;
  }
}